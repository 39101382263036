<template>
<!--    <StepBar id="StepBar"></StepBar>-->
<!--    <v-divider style="margin-top: 80px; margin-bottom: 30px;"></v-divider>-->
    <router-view id="content" style="min-width: 740px;position: relative; width: calc(100% - 285px); left: 256px; top: 100px; margin: 0px 15px; background-color: white; padding: 20px 15px; border-radius: 5px;box-shadow: 5px 4px 2px 0 rgb(60 75 100 / 14%), 0 3px 1px -2px rgb(60 75 100 / 12%), 0 1px 5px 0 rgb(60 75 100 / 20%);"></router-view>
</template>

<script>
import StepBar from "@/components/explore_content/step_bar/StepBar";
import axios from "axios";
import store from "@/store/index.ts";

export default {
  name: "ExploreContent",
  data() {
    return {};
  },
  props: {
    selected: String
  },
  components: {
    StepBar
  },
  async beforeRouteEnter(to, from, next) {
    const dataSetNameEn = store.getters.getDataSet.find( obj => obj.dataSetRepresentativeName === store.getters.getSelectedDataSet ).dataSet;

    // dataSet에 맞는 카테고리 데이터 불러오기
    const requestUrl = `https://${process.env.VUE_APP_API_BASE_URL}:${process.env.VUE_APP_API_BASE_PORT}/api/getLandingPageCategoryInfo?dataSet=${dataSetNameEn}`
    const cateData = await axios.get(requestUrl,{headers: {
        Authorization: store.getters.getLoginToken
      }});
    store.commit("setCategory", cateData.data);
    next();
  },
  beforeCreate() {
  },
  methods: {}
};
</script>
<style>
a {
  text-decoration: none;
  color: #8b8b94;
}

/* datatables */
#datatables_filter > label {
  display: block;
}
#datatables_wrapper > div:nth-child(1) > div:nth-child(1) {
  display: none;
}

#datatables_filter > label > input {
  margin-top: 20px;
}

#datatables_wrapper {
  margin: 15px;
}

.skeleton-loader .v-skeleton-loader__bone {
  height: 32px !important;
  width: 100%;
  padding: 0;
}
</style>
