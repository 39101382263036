<template>
  <div>
    <!--    1차 카테고리-->
    <div
      class="cate-container"
      :style="
        this.$route.name === 'ExploreContentFirstCategory'
          ? {
              width: `${43}%`
            }
          : {
              width: `${19}%`,
              cursor: 'pointer'
            }
      "
      v-on:click="
        $router
          .push({
            name: 'ExploreContentFirstCategory',
            query: {
              dataSet: $store.getters.getSelectedDataSet,
              section: $route.query.section
            }
          })
          .catch(err => {})
      "
    >
      <div
        class="selected-cate"
        :style="
          this.$route.name === 'ExploreContentFirstCategory'
            ? {
                'font-size': `${18}px`,
                'background-color': '#475c80',
                color: 'white',
                'font-style' : firstCate==='선택한 카테고리' ? 'italic':'',
              }
            : {
                'font-size': `${16}px`,
                'font-weight': '300'
              }
        "
      >
        <v-icon
          style="color: #aaaaaa
         ;"
          :style="
            this.$route.name === 'ExploreContentFirstCategory'
              ? {
                  color: 'white',
                  'font-size': `${30}px`,
                }
              : {
                  'font-size': `${16}px`
                }
          "
          >mdi-numeric-1-circle-outline</v-icon
        >
        {{ firstCate }}
      </div>
      <span class="arrow-front"></span>
      <span
        class="arrow-end"
        :style="
          this.$route.name === 'ExploreContentFirstCategory'
            ? {
                'border-left': '36px solid #475c80'
              }
            : {}
        "
      ></span>
    </div>

    <!--    2 차 카테고리-->
    <div
      class="cate-container"
      :style="
        this.$route.name === 'ExploreContentSelectSecondThirdCategory'
          ? {
              width: `${31}%`
            }
          : {
              width: `${19}%`,
              cursor: 'pointer'
            }
      "
      v-on:click="
        $router
          .push({
            name: 'ExploreContentSelectSecondThirdCategory',
            query: {
              dataSet: $store.getters.getSelectedDataSet,
              firstCate: firstCate,
              secondCate: secondCate,
              section: $route.query.section
            }
          })
          .catch(err => {})
      "
    >
      <div
        class="selected-cate"
        :style="
          this.$route.name === 'ExploreContentSelectSecondThirdCategory'
            ? {
                'font-size': `${18}px`,
                'background-color': '#475c80',
                color: 'white',
              }
            : {
                'font-size': `${16}px`,
                'font-weight': '300',
                'font-style' : secondCate==='선택한 카테고리' ? 'italic':''
              }
        "
      >
        <v-icon
          style="color: #aaaaaa"
          :style="
            this.$route.name === 'ExploreContentSelectSecondThirdCategory'
              ? {
                  color: 'white',
                  'font-size': `${30}px`
                }
              : {
                  'font-size': `${16}px`
                }
          "
          >mdi-numeric-2-circle-outline</v-icon
        >
        {{ secondCate }}
      </div>
      <span class="arrow-front"></span>
      <span
        class="arrow-end"
        :style="
          this.$route.name === 'ExploreContentSelectSecondThirdCategory'
            ? {
                'border-left': '36px solid #475c80'
              }
            : {}
        "
      ></span>
    </div>

    <!--    3차 카테고리-->
    <div
      class="cate-container"
      :style="
        this.$route.name === 'ExploreContentSelectSecondThirdCategory'
          ? {
              width: `${31}%`
            }
          : {
              width: `${19}%`,
              cursor: 'pointer'
            }
      "
      v-on:click="
        $router
          .push({
            name: 'ExploreContentSelectSecondThirdCategory',
            query: {
              dataSet: $store.getters.getSelectedDataSet,
              firstCate: firstCate,
              secondCate: secondCate,
              section: $route.query.section
            }
          })
          .catch(err => {})
      "
    >
      <div
        class="selected-cate"
        :style="
          this.$route.name === 'ExploreContentSelectSecondThirdCategory'
            ? {
                'font-size': `${18}px`,
                'background-color': '#475c80',
                color: 'white',
                'font-style' : thirdCate==='선택한 카테고리' ? 'italic':''
              }
            : {
                'font-size': `${16}px`,
                'font-weight': '300',
                'font-style' : thirdCate==='선택한 카테고리' ? 'italic':''
              }
        "
      >
        <v-icon
          style="color: #aaaaaa"
          :style="
            this.$route.name === 'ExploreContentSelectSecondThirdCategory'
              ? {
                  color: 'white',
                  'font-size': `${30}px`
                }
              : {
                  'font-size': `${16}px`
                }
          "
          >mdi-numeric-3-circle-outline</v-icon
        >
        {{ thirdCate }}
      </div>
      <span class="arrow-front"></span>
      <span
        class="arrow-end"
        :style="
          this.$route.name === 'ExploreContentSelectSecondThirdCategory'
            ? {
                'border-left': '36px solid #475c80'
              }
            : {}
        "
      ></span>
    </div>
    <div
      class="cate-container"
      :style="
        this.$route.name === 'ExploreContentSelectFourthCategory'
          ? {
              width: `${43}%`
            }
          : {
              width: `${19}%`
            }
      "
    >
      <div
        class="selected-cate"
        :style="
          this.$route.name === 'ExploreContentSelectFourthCategory'
            ? {
                'font-size': `${18}px`,
                'background-color': '#475c80',
                color: 'white'
              }
            : {
                'font-size': `${16}px`,
                'font-weight': '300'
              }
        "
      >
        <v-icon
          style="color: #aaaaaa
         ;"
          :style="
            this.$route.name === 'ExploreContentSelectFourthCategory'
              ? {
                  color: 'white',
                  'font-size': `${30}px`
                }
              : {
                  'font-size': `${16}px`
                }
          "
          >mdi-numeric-4-circle-outline</v-icon
        >
        내용보기
      </div>
    </div>
  </div>

  <!--  <div-->
  <!--    class="nav-progress"-->
  <!--    style="position: relative; min-width:750px; background-color: white;  border-radius: 15px;"-->
  <!--  >-->
  <!--    &lt;!&ndash;    1차 카테고리 시작&ndash;&gt;-->
  <!--    <div-->
  <!--      v-if="this.$route.name === 'ExploreContentFirstCategory'"-->
  <!--      style="width: 40%; background-color: #3c4b64; color: white ;transition-duration: 1s; "-->
  <!--    >-->

  <!--      <div style="font-weight: 200; font-size: 20px">-->
  <!--        {{ firstCate }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div-->
  <!--      v-else-->
  <!--      style="cursor: pointer;transition-duration: 1s;width: 20%"-->
  <!--        v-on:click="-->
  <!--          $router-->
  <!--            .push({-->
  <!--              name: 'ExploreContentFirstCategory',-->
  <!--              query: {-->
  <!--                dataSet: $store.getters.getSelectedDataSet,-->
  <!--                section: $route.query.section-->
  <!--              }-->
  <!--            })-->
  <!--            .catch(err => {})-->
  <!--        "-->
  <!--    >-->
  <!--      <span style="font-size: 15px">1차 카테고리</span>-->
  <!--      <div-->
  <!--        style="color: darkcyan;; font-size: 16px; margin-top: 5px;  text-decoration: underline"-->
  <!--      >-->
  <!--        {{ firstCate }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;    1차 카테고리 끝&ndash;&gt;-->

  <!--    &lt;!&ndash;    2차 카테고리 시작&ndash;&gt;-->
  <!--    <div-->
  <!--      v-if="this.$route.name === 'ExploreContentFirstCategory'"-->
  <!--      style="background-color: #ffffff;  color: #939393;width: 20%;transition-duration: 1s;"-->
  <!--    >-->
  <!--      <span style="font-size: 15px; color: #939393">2차 카테고리</span>-->
  <!--      <div style="font-weight: 200; font-size: 20px">-->
  <!--        {{ secondCate }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div-->
  <!--      v-else-if="this.$route.name === 'ExploreContentSelectSecondThirdCategory'"-->
  <!--      style="background-color: #3c4b64; color: white;width: 30%;transition-duration: 1s;"-->
  <!--    >-->
  <!--      <span style="font-size: 15px; color: #d3daff">2차 카테고리</span>-->
  <!--      <div style="font-weight: 200; font-size: 20px">-->
  <!--        {{ secondCate }}-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div-->
  <!--      v-else-->
  <!--        v-on:click="-->
  <!--          $router-->
  <!--            .push({-->
  <!--              name: 'ExploreContentSelectSecondThirdCategory',-->
  <!--              query: {-->
  <!--                dataSet: $store.getters.getSelectedDataSet,-->
  <!--                firstCate: firstCate,-->
  <!--                secondCate: secondCate,-->
  <!--                section: $route.query.section-->
  <!--              }-->
  <!--            })-->
  <!--            .catch(err => {})-->
  <!--        "-->
  <!--      style=" transition-duration: 1s;width: 20%"-->
  <!--    >-->
  <!--      <span style="font-size: 15px">2차 카테고리</span>-->
  <!--      <div-->
  <!--        style="color: darkcyan; font-size: 16px; margin-top: 5px;  text-decoration: underline"-->
  <!--      >-->
  <!--        {{ secondCate }}-->
  <!--        &lt;!&ndash;        <span style="font-size: 10px; color: darkred; font-weight: 200">({{ secondCateRank }}위)</span>&ndash;&gt;-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;    2차 카테고리 끝&ndash;&gt;-->

  <!--    &lt;!&ndash;    3차 카테고리 시작&ndash;&gt;-->
  <!--    <div-->
  <!--      v-if="this.$route.name === 'ExploreContentFirstCategory'"-->
  <!--      style="background-color: #ffffff; color: white;width: 20%;transition-duration: 1s;"-->
  <!--    >-->
  <!--      <span style="font-size: 15px; color: #939393">3차 카테고리</span>-->
  <!--      <div style="font-weight: 200; font-size: 20px">-->
  <!--        {{ thirdCate }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div-->
  <!--      v-else-if="this.$route.name === 'ExploreContentSelectFourthCategory'"-->
  <!--      style="background-color: #ffffff; width: 20%;transition-duration: 1s;"-->
  <!--      v-on:click="-->
  <!--        $router-->
  <!--          .push({-->
  <!--            name: 'ExploreContentSelectSecondThirdCategory',-->
  <!--            query: {-->
  <!--              dataSet: $store.getters.getSelectedDataSet,-->
  <!--              firstCate: firstCate,-->
  <!--              secondCate: secondCate,-->
  <!--              section: $route.query.section-->
  <!--            }-->
  <!--          })-->
  <!--          .catch(err => {})-->
  <!--      "-->
  <!--    >-->
  <!--      <span style="font-size: 15px; color: #939393">3차 카테고리</span>-->
  <!--      <div-->
  <!--        style="color: darkcyan;; font-size: 16px; margin-top: 5px;  text-decoration: underline"-->
  <!--      >-->
  <!--        {{ thirdCate }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div-->
  <!--      v-else-->
  <!--      style="background-color: #3c4b64; color: white; transition-duration: 1s;width: 30%"-->
  <!--    >-->
  <!--      <span style="font-size: 15px; color: #d3daff">3차 카테고리</span>-->
  <!--      <div style="font-weight: 200; font-size: 20px">-->
  <!--        {{ thirdCate }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;    3차 카테고리 끝&ndash;&gt;-->

  <!--    &lt;!&ndash;    4차 카테고리&ndash;&gt;-->
  <!--    <div-->
  <!--      v-if="this.$route.name === 'ExploreContentSelectFourthCategory'"-->
  <!--      style="background-color: #3c4b64; color: white;width: 40%;transition-duration: 1s;"-->
  <!--    >-->
  <!--      <span style="font-size: 15px; color: #939393">4차 카테고리</span>-->
  <!--      <div style="font-weight: 200; font-size: 20px">-->
  <!--        &lt;!&ndash;        {{ thirdCate }}&ndash;&gt;-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div-->
  <!--      v-else-->
  <!--      style="background-color: #ffffff; color: white; transition-duration: 1s;width: 30%"-->
  <!--    >-->
  <!--      <span style="font-size: 15px;  color: #939393">4차 카테고리</span>-->
  <!--      <div style="font-weight: 200; font-size: 20px">-->
  <!--        {{ thirdCate }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    &lt;!&ndash;    4차 카테고리 끝&ndash;&gt;-->
  <!--  </div>-->
</template>

<script>
import EventBus from "@/EventBus";

export default {
  name: "StepBar",
  components: {},
  props: {
    step: String,
    selectedCate: String
  },
  data() {
    return {
      cateData: this.$store.getters.getCategoryData,
      firstCate:
        this.$route.query.firstCate === undefined
          ? "선택한 카테고리"
          : this.$route.query.firstCate,
      secondCate:
        this.$route.query.secondCate === undefined
          ? "선택한 카테고리"
          : this.$route.query.secondCate,
      thirdCate:
        this.$route.query.thirdCate === undefined
          ? "선택한 카테고리"
          : this.$route.query.thirdCate
    };
  },

  created() {
    EventBus.$on(
      "updateExploreStepBarFirstCateAndSecondCate", (mouseHoverFirstCategory, mouseHoverSecondCategory) => {
        this.firstCate = mouseHoverFirstCategory;
        this.secondCate = mouseHoverSecondCategory;
      }
    );
    EventBus.$on("updateExploreStepBarFirstCate", (firstCate) => {
      this.firstCate = firstCate;
    });
    EventBus.$on("updateExploreStepBarSecondCate", (firstCate, SecondCate) => {
      this.secondCate = SecondCate;
      this.thirdCate = null;
    });
    EventBus.$on("updateExploreStepBarThirdCate", thirdCate => {
      this.thirdCate = thirdCate;
    });
  },
  watch: {
    $route(to, from) {
      if (to.name === "ExploreContentFirstCategory") {
        this.secondCate = "선택한 카테고리";
        this.thirdCate = "선택한 카테고리";
      } else if (to.name === "ExploreContentSelectSecondThirdCategory") {
        this.secondCate = this.$route.query.secondCate;
        this.thirdCate = "선택한 카테고리";
      } else if (to.name === "ExploreContentSelectFourthCategory") {
        this.thirdCate = this.$route.query.thirdCate;
      }
    }
  }
};
</script>

<style scoped>
.step-bar-container {
  text-align: center;
  min-width: 720px;
}
.cate-container {
  position: relative;
  display: inline-block;
  text-align: center;
  transition-duration: 1s;
}
.category-text {
  text-align: left;
  padding-left: 0px;
  font-size: 14px;
}
.selected-cate {
  position: absolute;
  color: #aaaaaa;
  font-weight: 700;
  background-color: #ebebeb;
  padding-top: 14px;
  padding-left: 14px;
  height: 56px;
  width: 100%;
}

.arrow-front {
  position: absolute;
  top: -4px;
  left: 100%;
  border-top: 32px solid transparent;
  border-left: 40px solid #ffffff;
  border-bottom: 32px solid transparent;
  z-index: 10;
  display: inline-block;
}
.arrow-end {
  content: "";
  position: absolute;
  left: 100%;
  border-top: 28px solid transparent;
  border-left: 36px solid #ebebeb;
  border-bottom: 28px solid transparent;
  z-index: 20;
}
#StepBar > div:nth-child(1) > div {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

#StepBar > div:nth-child(4) > div {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
/*div {*/
/*  font-size: 16px;*/
/*  font-family: "Rubik", sans-serif;*/
/*  font-weight: bold;*/
/*  text-align: center;*/
/*}*/

/*.nav-progress {*/
/*  display: table;*/
/*  overflow: hidden;*/
/*  margin: 0;*/
/*  width: 100%;*/
/*  background-color: #f7f7f7;*/
/*  border: 0;*/
/*  box-shadow: 5px 4px 2px 0 rgba(60, 75, 100, 0.14),*/
/*    0 3px 1px -2px rgba(60, 75, 100, 0.12), 0 1px 5px 0 rgba(60, 75, 100, 0.2);*/
/*}*/

/*.nav-progress > div {*/
/*  position: relative;*/
/*  display: table-cell;*/
/*  padding: 16px 0;*/
/*  color: #939393;*/
/*  text-align: center;*/
/*}*/
</style>
